import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { complementaryFont } from '@/components/common/complementaryColours';
import { Paragraph } from '@/components/common/MarkUp';

import type { SanityCtaCardType } from '@/types/sanity';
import type { ReactElement } from 'react';

export interface CtaCardProps extends Pick<SanityCtaCardType, 'heading'> {
  _type?: 'ctaCard';
  backgroundColour?: SanityCtaCardType['backgroundColour'];
  actions?: ReactElement;
  body?: ReactElement;
  bodyMarginTop?: number;
}

export const CtaCard = ({
  heading,
  body,
  actions,
  backgroundColour = '--surface-warm-base',
  bodyMarginTop = 50,
}: CtaCardProps) => {
  return (
    <CtaCardContainer backgroundColour={backgroundColour}>
      {heading && (
        <CtaCardHeader>
          <Paragraph size="heading-md">{heading}</Paragraph>
        </CtaCardHeader>
      )}
      <CtaCardBody marginTop={bodyMarginTop}>{body}</CtaCardBody>
      {!!actions && <CtaCardActions>{actions}</CtaCardActions>}
    </CtaCardContainer>
  );
};

const CtaCardContainer = styled.div<{ backgroundColour }>`
  border-radius: var(--radius-m);
  padding: var(--spacing-small);
  display: flex;
  flex-direction: column;
  width: 100%;

  position: relative;

  background-color: ${({ backgroundColour }) => `var(${backgroundColour})`};
  color: ${({ backgroundColour }) =>
    `var(${complementaryFont(backgroundColour)})`};

  ${screen.md} {
    padding: var(--spacing-250);
  }

  p {
    color: ${({ backgroundColour }) =>
      `var(${complementaryFont(backgroundColour)})`};
  }
`;

const CtaCardHeader = styled.div``;

const CtaCardBody = styled.div<{ marginTop: CtaCardProps['bodyMarginTop'] }>`
  flex-grow: 1;
  margin-top: var(--spacing-${({ marginTop }) => marginTop});
`;

const CtaCardActions = styled.div`
  margin-top: var(--spacing-x-small);

  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xx-small);

  ${screen.md} {
    margin-top: var(--spacing-small);
  }
`;
