import styled from '@emotion/styled';

import { subheadingSmall } from '@/styles/typography';

const colours = {
  // these have been left in case we want to implement different colour comboes
  default: {
    primary: '--surface-primary',
    accent: '--surface-warm-base',
  },
  standard: {
    primary: '--surface-primary',
    accent: '--surface-warm-base',
  },
};

export interface Tab {
  label: string;
  variant: Variant;
  content: object;
  trackingTag?: { blockName: string; blockAction: string };
}

export enum Variant {
  default = 'default',
  standard = 'standard',
}

export interface TabControlProps {
  tabs: Tab[];
  setSelectedTab: (index: number) => void;
  selectedTab: number;
  componentClassName?: string;
  idPrefix: string;
}

interface StyleProps {
  variant: Variant;
  isActive: boolean;
}

export const TabControl = ({
  tabs,
  setSelectedTab,
  selectedTab,
  componentClassName,
  idPrefix,
}: TabControlProps) => {
  return (
    <TabList
      variant={
        tabs[selectedTab].variant ? tabs[selectedTab].variant : Variant.default
      }
      role="tablist"
      id={`${idPrefix}-tabpanel`}
    >
      {tabs.map((tab, index) => {
        const isActive = index === selectedTab;
        return (
          <Tab
            key={`${tab.label}-tab-control`}
            type="button"
            variant={tab.variant ?? Variant.default}
            isActive={isActive}
            onClick={() => setSelectedTab(index)}
            role="tab"
            aria-selected={isActive}
            aria-controls={`${idPrefix}-tabpanel`}
            id={`${idPrefix}-tab-${index}`}
            className={
              // eslint-disable-next-line prefer-template
              `${componentClassName}__tab-switcher ${
                tab.trackingTag
                  ? `tracking-${tab.trackingTag?.blockName}__${tab.trackingTag?.blockAction}`
                  : ''
              }`
            }
          >
            {tab.label}
          </Tab>
        );
      })}
    </TabList>
  );
};

const TabList = styled.div<Omit<StyleProps, 'isActive'>>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--spacing-small);

  width: 100%;
  padding: var(--spacing-xxx-small) var(--spacing-xxx-small);
  margin: 0 0 var(--spacing-x-large) 0;

  list-style: none;
  border-radius: var(--radius-400);

  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tab = styled.button<StyleProps>`
  ${subheadingSmall}
  display: flex;
  align-items: center;
  width: min-content;
  border-radius: var(--radius-400);
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: var(--spacing-xxx-small) var(--spacing-x-small);
  height: 32px;
  transition: background-color 0.3s ease;
  background-color: var(
    ${(props) => (props.isActive ? colours[props.variant].primary : null)}
  );
  color: ${({ isActive }) =>
    isActive ? `var(--text-inverse-strong)` : `var(--text-action-dark)`};

  &:hover {
    color: var(--text-action-dark);
    background-color: var(--surface-primary-subtle);
  }

  &:focus {
    outline: 2px solid var(--border-action-dark);
    outline-offset: 2px;
    background-color: var(--transparent);
    color: var(--text-action-dark);
    background-color: ${(props) =>
      props.isActive ? `var(--surface-primary)` : `var(--transparent)`};
    color: ${(props) =>
      props.isActive
        ? `var(--text-inverse-strong)`
        : `var(--text-action-dark)`};
    &:hover {
      background-color: ${(props) =>
        props.isActive
          ? `var(--surface-primary)`
          : `var(--surface-primary-subtle)`};
      color: ${(props) =>
        props.isActive
          ? `var(--text-inverse-strong)`
          : `var(--text-action-dark)`};
    }
  }

  :focus:not(:focus-visible) {
    outline: 0;
  }
`;
