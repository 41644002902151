const darkColours = [
  '--surface-inverse-subtle',
  '--surface-inverse-base',
  '--surface-inverse-strong',
  '--surface-primary',
  '--surface-inverse-primary',
  '--surface-inverse-danger',
  'navy',
];

export const complementaryFont = (colour) => {
  return darkColours.includes(colour)
    ? '--text-inverse-strong'
    : '--text-strong';
};
