import { useState } from 'react';
import styled from '@emotion/styled';

import { useId } from '@/lib/useId';

import { TabControl } from './TabControl';

import type { Tab } from './TabControl';

const isJest = process.env.JEST_WORKER_ID;

interface StyleProps {
  isActive: boolean;
}
export interface TabViewProps {
  tabs: Tab[];
}

const TabViewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabPanel = styled.div<StyleProps>`
  display: ${(props) => (props.isActive ? 'block' : 'none')};
`;

export const TabView = ({ tabs, ...props }) => {
  const id = useId();
  const tabViewId = isJest ? 'tabview-id' : id;
  const [selectedTab, setSelectedTab] = useState(0);
  const hasMultipleTabs = tabs.length > 1;

  return (
    <TabViewContainer {...props}>
      {hasMultipleTabs && (
        <TabControl
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          idPrefix={tabViewId}
        />
      )}

      {tabs.map((tab, index) => (
        <TabPanel
          key={`${tabViewId}-${tab.label}-tab-panel`}
          id={`${tabViewId}-tab-panel-${index}`}
          isActive={index === selectedTab}
          role={hasMultipleTabs ? 'tabpanel' : null}
          aria-labelledby={hasMultipleTabs ? `${tabViewId}-tab-${index}` : null}
        >
          <div>{tab.content}</div>
        </TabPanel>
      ))}
    </TabViewContainer>
  );
};
